<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <Loader v-model="loading" />
        <Panel header="Configuracion de Promociones">
          <TabView @tab-change="onTabChange" :activeIndex="tab">
            <TabPanel header="Promocion">
               <PromocionUI ref="promocion"/>
            </TabPanel>
        </TabView>
        </Panel>
    </div>
  </div>
</template>
<script>
import PromocionUI from "./Promociones/Promocion.vue";

import Loader from "../../components/general/Loader.vue";
export default {
  components: { PromocionUI, Loader },
  data() {
    return {
        tab:0,
        loading: false
    };
  },
  methods: {
    onTabChange(payload) {
      switch (payload.index) {
        case 0:
          this.$refs.promocion.refresh();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.$refs.promocion.refresh();
  }
};
</script>

<style>
</style>

