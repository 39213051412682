<template>
  <Dialog
    :closeOnEscape="true"
    :closable="true"
    :modal="true"
    :maximizable="true"
    :header="header"
    :style="style"
    v-model:visible="localValue.visible"
  >
    <div class="card">
      <div v-if="lazy" class="p-fluid formgrid grid">
        <slot name="filters"></slot>
        <div class="col-12"><br>
          <Button style="width: 100%" icon="pi pi-check" @click="onFilter" class="p-button-info">Buscar</Button>
        </div>
      </div>
      <BasicDatatable 
        :exportable="false"
        :selectionMode="'single'" 
        @selected="select" 
        @selected-detail="selectDetail"
        :dataKey="dataKey" 
        :headers="headers" 
        :rows="rows"
        :expandable="expandable"
        :headersExpandable="headersExpandable"
        :rowsExpandable="rowsExpandable" />
    </div>
  </Dialog>
</template>

<script>
var math = require("mathjs");

import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import BasicDatatable from "../general/BasicDatatable.vue";
export default {
  emits: ['selected'],
  components: {
    BasicDatatable
  },
  props: {
    modelValue: {
      required: true,
    },
    dataKey: {
        type: String,
        default: "id"
    },
    header: {
        type: String,
        default: "Ayuda"
    },
    headers: {
        type: Array,
        default: () => []
    },
    //* QUIEN USA ESTO, CREO QUE ES BW
    headersExpandable: {
        type: Array,
        default: () => []
    },
    rows: {
        type: Array,
        default: () => []
    },
    rowsExpandable: {
        type: String,
        default: ""
    },
    lazy: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    style: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      filters: {},
      localValue: this.modelValue,
      expandedRows: []
    };
  },
  methods: {
      selectDetail(payload) {
          this.$emit("selected-detail", payload);
          this.localValue.visible = false;
      },
      select(payload) {
          this.$emit("selected", payload);
          this.localValue.visible = false;
      },
      onFilter() {
          this.$emit("filter");
      },
      getValue(value, header){
         if (header.type == "currency") 
            return Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
            }).format(value);
          else if (header.type == "upper") 
            return value.toUpperCase()
          else if (header.type == "percentage") 
            return parseFloat(value).toFixed(2)+"%"
          else if (header.type == "date") 
            return moment(value).format("DD/MM/YYYY");
          else if (header.type == "date-time") 
            return moment(value).format("DD/MM/YYYY HH:mm:ss A");
          else if (header.type == "time") 
            return moment(value).format("HH:mm:ss A");
          else if (header.type == "boolean") 
            return value ? 'SI' : 'NO';
          else if (header.type == "decimal")
            return (math.round(parseFloat(value) * 100) / 100).toFixed(2).toLocaleString('en-US');
          else 
              return value;
      }
  },
  created() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>
.p-dialog-content::-webkit-scrollbar {
	width: 5px;
	}

	/* Track */
.p-dialog-content::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
.p-dialog-content::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
.p-dialog-content::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}</style>
