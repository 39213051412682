<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <HelperDialog @selected="selectProduct" v-model="productHelper" :headers="productHeaders" :rows="products" ></HelperDialog>
      <Loader v-model="loading" />
      <Panel header="Promocion">
        <div class="formgrid grid p-fluid">
          <div class="col-12">
            <FormToolbar :actions="['new', 'save']" @new="newpromo" @save="savepromo" />
          </div>
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Clave" v-model="promo.key_name" />
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="promo.name" />
          <FormDropdown wrapperClass="field col-12 xl:col-4" label="Tipo" v-model="promo.type" :options="[
            { id: 'INDIVIDUAL' },
            { id: 'COLECTIVA' },
            { id: 'SOBRECOMPRA' },
          ]" optionLabel="id" optionValue="id" />
          <FormDropdown wrapperClass="field col-12 xl:col-4" label="Subtipo" v-model="promo.subtype" :options="subtypes"
            optionLabel="id" optionValue="id" />
          <FormCalendar wrapperClass="field col-12 xl:col-4" label="Fecha Inicial" v-model="promo.initial_date">
          </FormCalendar>
          <FormCalendar wrapperClass="field col-12 xl:col-4" label="Fecha Final" v-model="promo.final_date">
          </FormCalendar>
          <FormInputText v-if="promo.subtype == 'PRECIO PROMOCIONAL'" wrapperClass="field col-12 xl:col-4"
            label="Producto" v-model="promo.id_pos_product_name" :search="true" @search="productHelper.visible = true" />
          <FormInputNumber v-if="promo.subtype == 'PRECIO PROMOCIONAL'" wrapperClass="field col-12 xl:col-4"
            label="Precio" v-model="promo.price" :mode="'currency'" :minFractionDigits="2" :maxFractionDigits="2" />
          <FormDropdown v-if="promo.type == 'COLECTIVA'" wrapperClass="field col-12 xl:col-4" label="Aplica en"
            v-model="promo.subtype_colective" :options="[
              { id: 1, name: 'TODOS LOS PRODUCTOS' },
              { id: 2, name: 'UNO O MAS PRODUCTOS' },
              { id: 3, name: 'UNO O MAS FAMILIAS' },
            ]" optionLabel="name" optionValue="name" />
          <div class="field col-12 xl:col-8" v-if="promo.subtype_colective == 'UNO O MAS PRODUCTOS'">
            <label>Productos</label>
            <MultiSelect v-model="promo.products" :options="families" optionLabel="name" optionValue="id"
              optionGroupChildren="items" optionGroupLabel="name" />
          </div>
          <div class="field col-12 xl:col-8" v-if="promo.subtype_colective == 'UNO O MAS FAMILIAS'">
            <label>Familias</label>
            <MultiSelect v-model="promo.families" :options="families" optionLabel="name" optionValue="id" />
          </div>
          <FormInputNumber v-model="promo.limit_sale" v-if="promo.subtype == 'DESCUENTO POR CANTIDAD'"
            wrapperClass="field col-12 xl:col-4" :label="'Limite al Descuento'"
            :tooltip="'Limite en el cual se aplica el monto a descontar, por ejemplo si la compra no llega a $5,000.00 no se aplica el descuento $250.00'"
            :mode="'currency'" :minFractionDigits="2" :maxFractionDigits="2" />
          <FormInputNumber v-if="
            promo.subtype == 'DESCUENTO POR CANTIDAD' ||
            promo.subtype == 'CUPON' ||
            promo.subtype == 'DESCUENTO POR MEDIO DE PAGO'
          " wrapperClass="field col-12 xl:col-4" :label="'Importe Descuento'" v-model="promo.discount_amount"
            :mode="'currency'" :minFractionDigits="2" :maxFractionDigits="2" />
        </div>
      </Panel>
    </div>
    <div class="col-12">
      <br />
      <BasicDatatable :rows="promos" :selectionMode="'single'" :headers="headers" @selected="selectpromo" />
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import formMixin from "../../../mixins/form.js";

import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from "../../../utilities/General";
import FormCalendar from "../../../components/general/FormCalendar.vue";


import { promo } from "../../../models/promo";
import { product } from "../../../models/product";
import { family } from "../../../models/family";
import HelperDialog from '../../../components/general/HelperDialog.vue';
export default {
  mixins: [formMixin],
  expose: ["refresh"],
  components: {
    HelperDialog,
    FormInputText,
    FormCalendar,
    FormInputNumber,
    FormDropdown,
    FormToolbar,
    Loader,
    BasicDatatable,
  },
  data() {
    return {
      productHelper: {
        visible: false
      },
      loading: false,
      promo: new promo(),
      promos: [],
      products: [],
      families: [],
      productHeaders: [
        new HeaderGrid("Clave", 'key_name'),
        new HeaderGrid("Nombre", 'name'),
        new HeaderGrid("Codigo de Barra", 'codebar')
      ],
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Nombre", "name"),
      ],
    };
  },
  computed: {
    subtypes() {
      if (this.promo.type == "INDIVIDUAL")
        return [{ id: "PRECIO PROMOCIONAL" }];
      else if (this.promo.type == "COLECTIVA")
        return [{ id: "2X1" }, { id: "3X2" }, { id: "DESCUENTO POR CANTIDAD" }];
      else if (this.promo.type == "SOBRECOMPRA")
        return [
          { id: "CUPON" },
          { id: "DESCUENTO POR MEDIO DE PAGO" },
          { id: "FREE SHIPPING" },
        ];
      else return [];
    },
  },
  watch: {
    ["promo.subtype_colective"](newValue, oldValue) {
      if (oldValue != null) {
        console.log(newValue);
        this.promo.products = [];
        this.promo.families = [];
      }
    },
    ["promo.type"](newValue, oldValue) {
      if (oldValue != null) {
        console.log(newValue);
        this.promo.subtype_colective = null;
        this.promo.subtype = null;
      }
    },
  },
  methods: {
    selectProduct(product) {
      this.promo.id_pos_product = product.id;
      this.promo.id_pos_product_name = product.name;
    },
    selectpromo(entity) {
      this.promo = fillObject(this.promo, entity);
    },
    newpromo() {
      this.promo = new promo(this.session);
    },
    async refresh() {
      this.loading = true;
      this.promo = new promo(this.session);
      try {
        this.promos = await this.promo.all();
        this.products = await new product(this.session).all();
        this.families = await new family(this.session).all();
        this.families.forEach((family) => {
          family.name = family.name.toUpperCase();
          family.items = this.products.filter(
            (p) => p.id_pos_family == family.id
          );
        });
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async savepromo() {
      this.loading = true;
      try {
        if (this.promo.id) {
          let editedpromo = await this.promo.update();
          this.promos[this.promos.findIndex((x) => x.id == this.promo.id)] = editedpromo;
        } else {
          var newpromo = await this.promo.save();
          this.promos.push(newpromo);
        }
        this.newpromo();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>

</style>

