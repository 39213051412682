import axios from "axios";

export class promo
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.name = null;
        this.key_name = null;
        this.type = null;
        this.subtype = null;
        this.initial_date = new Date();
        this.final_date = null;
        this.id_pos_product = null;
        this.price = 0.00;
        this.discount_amount = 0.00;
        this.discount_percentage = 0.00;
        this.id_pos_payment_type = null;
        this.limit_sale = 0.00;
        this.created = null;
        this.created_by = null;
        this.modified = null;
        this.modified_by = null;

        this.products = [];
        this.families = [];
    }

    async all() {
        let response = await axios.get("tpv/promo", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/promo", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}