<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <Calendar 
        v-model="localValue" 
        :showIcon="true" 
        :disabled="disabled"
        :dateFormat="dateFormat"
        :showTime="showTime" 
        :showSeconds="showSeconds"
        :class="{ 'p-invalid': valid === false }"
        :view="view" /> 
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'date'
    },
    dateFormat: {
      type: String,
      default: 'dd/mm/yy'
    },
    modelValue: {
      type: [String, Date, null],
      required: true,
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: String,
        default: null
    },
    showTime: {
        type: Boolean,
        default: null
    },
    showSeconds: {
        type: Boolean,
        default: null
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>

</style>